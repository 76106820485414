export const ttl = 1000 * 60 * 60 * 24 * 90; // 90 days

export function getStorage() {
  try {
    const sessionTest = 'sessiontest';

    const context = window.localStorage || window.sessionStorage;

    if (context) {
      context.setItem(sessionTest, sessionTest);
      context.removeItem(sessionTest);

      return context;
    }
  } catch (e) {
    //
  }

  return {};
}

export function getLocalTest(storage, key) {
  try {
    return storage[key] ? JSON.parse(storage[key]) || {} : {};
  } catch (e) {
    return {};
  }
}

export function removeTestStorage(storage, key) {
  try {
    storage.removeItem(key);
  } catch (e) {
    //
  }
}

export function sorter(a, b) {
  return b.value - a.value;
}
