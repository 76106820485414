import {reusable} from '@convincely/utils/lib/services/convincely.js';

export const initState = reusable('initState', {
  enabled: false,
});

export function initAntiFlicker() {
  document.documentElement.style.setProperty('opacity', '0');
  initState.enabled = true;
  setTimeout(initLoad, 5000); // emergency exit if app-index doesn't load for whatever reason
}

export function initLoad() {
  if (initState.enabled) {
    document.documentElement.style.removeProperty('opacity');
    initState.enabled = false;
  }
}
