import {conf} from '@convincely/utils/lib/services/conf.js';
import {defined} from '@convincely/utils/lib/defined.js';
import {toggleTestStorageKey} from 'funnel/src/routes/experiments/constants.js';
import {analyticsService} from '@convincely/utils/lib/services/analytics.js';
import {getLocalTest, getStorage, removeTestStorage, ttl} from '../utils/tests.js';
import {getCurrentPath} from '../utils/current-path.js';

const sorter = (a, b) => {
  return b.sortValue - a.sortValue;
};

function fireAnalyticsEvent(load) {
  analyticsService.track({
    event: 'ABTest',
    data: {
      funnel: conf.custom.funnel,
      variant: load ? 'Experiment' : 'Control',
    },
  });
}

export function processToggleTest(test, forceLoad) {
  const storage = getStorage();

  if (forceLoad) {
    fireAnalyticsEvent(true);

    return window[toggleTestStorageKey] = true;
  }

  if (test) {
    const browserPath = getCurrentPath();

    if (!test.permitted || test.permitted.some((url) => url === browserPath) && (!test.excluded || !test.excluded.some((url) => url === browserPath))) {
      const {value: localValue, ts: localTs} = getLocalTest(storage, toggleTestStorageKey);

      if (defined(localValue)) {
        if (localTs > Date.now() && test.segments) {
          fireAnalyticsEvent(localValue);

          if (localValue) {
            return window[toggleTestStorageKey] = localValue;
          }

          throw Error('Control detected - Convincely load aborted.');
        }
      }

      const {value} = test.segments.map((segment) => ({...segment, sortValue: segment.weight * Math.random()})).sort(sorter)[0];

      if (defined(value)) {
        storage[toggleTestStorageKey] = JSON.stringify({value, ts: Date.now() + ttl});

        fireAnalyticsEvent(value);

        if (value) {
          return window[toggleTestStorageKey] = value;
        }

        throw Error('Control detected - Convincely load aborted.');
      }
    }

    return window[toggleTestStorageKey] = false;
  } else {
    removeTestStorage(storage, toggleTestStorageKey);
  }
}
