import {conf} from '@convincely/utils/lib/services/conf.js';
import {urlParams} from '@convincely/utils/lib/url-params.js';

export const context = document.getElementById('convincely_script');
export const data = context ? {...context.dataset} : {};

data.funnel = urlParams.funnel || data.funnel;
conf.storageKey = `custom_${data.funnel}`;
conf.custom = data;

if (data.app) {
  conf.app = data.app;
  delete data.app;
}
