import {conf} from '@convincely/utils/lib/services/conf.js';
import {urlParams} from '@convincely/utils/lib/url-params.js';
import {getCurrentPath} from '../utils/current-path.js';

/**
 * @param {boolean?} recheck
 * @return {boolean}
 */
export function shouldLoadFunnel(recheck) {
  const {permittedPathnames} = conf;

  if (conf.isLocal || conf.isDevLocal || urlParams.cvly || (!recheck && conf.shouldLoadFunnel) || !permittedPathnames) {
    return true;
  }

  const browserPath = getCurrentPath();

  for (const regex of permittedPathnames) {
    if (regex.test(browserPath)) {
      return true;
    }
  }

  return false;
}
