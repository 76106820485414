import {conf} from '@convincely/utils/lib/services/conf.js';
import {urlParams} from '@convincely/utils/lib/url-params.js';
import {cleanupClsHide} from './_cls-fix.js';
import {shouldLoadFunnel} from './split-test.js';
import {initAntiFlicker} from './init-load.js';
import {processToggleTest} from './toggle-tests.js';

export function loadFunnel() {
  if (shouldLoadFunnel()) {
    const onLoad = conf.onLoad;
    const toggleKey = onLoad?.toggleKey || 'cvly';

    processToggleTest(conf.toggleTests, urlParams[toggleKey] || window[toggleKey]);

    const funnel = {
      js: [],
      css: [],
    };

    if (onLoad) {
      if (
        onLoad.antiFlicker === true
        || (onLoad.antiFlickerRegex && onLoad.antiFlickerRegex.some((regexExp) => regexExp.test(window.location.pathname)))
        || (onLoad.antiFlickerReferrer && onLoad.antiFlickerReferrer.some((referrer) => referrer === document.referrer))
      ) {
        initAntiFlicker();
      }

      if (onLoad.css) {
        if (!Array.isArray(onLoad.css)) {
          onLoad.css = [onLoad.css];
        }

        for (const css of onLoad.css) {
          funnel.css.push(`${conf.baseUrl}onload/${css}`);
        }
      }

      if (onLoad.js) {
        if (!Array.isArray(onLoad.js)) {
          onLoad.js = [onLoad.js];
        }

        for (const js of onLoad.js) {
          funnel.js.push(`${conf.baseUrl}onload/${js}`);
        }
      }

      if (onLoad.delayAppInjection) {
        funnel.delayAppInjection = true;
      }
    }

    return funnel;
  }

  cleanupClsHide();
  throw new Error('Convincely should not load on this page');
}
