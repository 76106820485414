import {injectStyleInline} from '@convincely/utils/lib/services/inject/style.js';

export const clsKey = 'conv-cls-optimise';
export const clsPreloadKey = 'conv-cls-preload';
export const clsPreloadClass = 'conv-cls-preload';
export const clsWidgetClass = 'conv-widget-container-cls';

export function hideUntilClsDone(css) {
  injectStyleInline(css, clsPreloadKey);
  setTimeout(cleanupClsHide, 2500); // emergency exit
}

export function cleanupClsHide() {
  document.getElementById(clsPreloadKey)?.remove();
}
